@import '../index.scss';

.quests {
    .text-banner {
        background: $bg-layer-transparent;
        font-size: 2rem;
        letter-spacing: -0.4rem;
        font-weight: bold;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-top: 1rem;
    }

    .text-season {
        font-size: 5rem;
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        letter-spacing: -1rem;
    }

    .portal-frame {
        border: 3px solid $color-yellow;
        border-radius: 2rem;
    }

    .strike-through {
        text-decoration: line-through;
        // text-decoration-thickness: 2px;
    }

    .btn.btn-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        background: rgba(255, 255, 255, 0.1);
        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    @media (width <= $breakpoint-tablet) {
        .text-season {
            font-size: 4rem;
            line-height: 0.5;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
}