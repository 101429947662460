body {
    background: url('./images/bg.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

html {
    scroll-behavior: smooth;
}

main {
    min-height: calc(100vh - 100px);
}

.main {
    transition: all .3s;
    overflow: hidden;

    .portal-frame {
        border: 2px solid rgb(255 255 255 / 50%);
        background-color: rgba(167, 118, 255, 0.55);
        backdrop-filter: blur(3px);
        padding: 2rem;
        border-radius: 12px;
        margin-top: 2rem;
        margin-bottom: 3rem;

        .title {
            font-size: 3.5rem;
            line-height: 0.5;
            letter-spacing: -0.5rem;
        }

        .languages {
            .language {
                font-size: 1.3rem;
                letter-spacing: -0.1rem;
            }
        }
    }

    .portal-header-frame {
        margin-bottom: 6rem;

        .portal-frame {
            >.sub-title {
                font-size: 3rem;
                letter-spacing: -0.5rem;
            }
        }

        .title {
            font-size: 5rem;
            letter-spacing: -1rem;
            font-weight: 900;
        }

        .text-desc-top {
            font-size: 2rem;
            letter-spacing: -0.4rem;
            margin-top: 2rem;
            margin-bottom: 0.5rem;
        }

        .text-desc-bottom {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
        }

        .caption {
            white-space: pre-line;
            vertical-align: bottom;
            font-family: "Montserrat", sans-serif;
        }

        &.type-2 {
            .portal-frame {
                .sub-title {
                    font-size: 5rem;
                    letter-spacing: -1rem;
                    font-weight: 900;
                }

                >.title {
                    line-height: 1;
                    font-size: 3rem;
                    letter-spacing: -0.5rem;
                }

                .text-desc-top {
                    font-weight: bold;
                }

                .caption {
                    font-size: 1.5rem;
                    // letter-spacing: -0.5rem;
                    padding-top: 1rem;
                }

                @media(width <=520px) {
                    .sub-title {
                        font-size: 4rem;
                    }
                }
            }

        }

        &.type-3 {
            .box {
                .box-desc {
                    .sub-title {
                        display: none;
                    }
                }
            }
        }

        &.card-game {
            .box {
                &.small {
                    .box-thumb {
                        height: auto;
                    }
                }

                .box-desc {
                    display: none;
                }

                .box-thumb {
                    border-radius: 12px;
                    overflow: hidden;
                    border: 2px solid #FFF;

                    img {
                        object-fit: cover;
                        padding-top: 0;
                        border-radius: 0;
                    }
                }
            }

            .cards {
                max-width: 800px;
                margin: auto;
            }
        }

        &.card-ecosystem {
            .box {
                cursor: default;

                img {
                    padding: 0.5rem 1rem;
                }

                &.small {
                    .box-thumb {
                        height: auto;
                    }
                }

                .box-desc {
                    display: none;
                }
            }

            // .cards {
            //     >div:nth-child(4) {
            //         .box-desc {
            //             .title {
            //                 &::after {
            //                     content: '3';
            //                     vertical-align: super;
            //                     font-size: 0.75em;
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        &.card-guide {
            .cards {
                @media (width > 820px) {
                    max-width: 80%;
                    margin: auto;
                }

                .box-desc {
                    .title {
                        margin-top: 0;
                        letter-spacing: -0.625rem;
                        line-height: 0.5;
                        margin-bottom: .5rem;
                        display: flex;
                        width: 100%;
                    }
                }

                .languages {
                    .language {
                        transition: all .2s;

                        &:hover {
                            background-color: #6200FF;
                        }
                    }
                }

                .box.small {
                    .box-thumb {
                        height: 15rem;
                        border: 2px solid #FFF;
                        border-radius: 12px;

                        img {
                            padding-top: 0;
                            object-fit: cover;
                            border-radius: 12px;
                        }
                    }
                }
            }
        }
    }

    .heading {
        padding-top: 1rem;
        padding-bottom: 0rem;

        h1 {
            font-size: 6.875rem;
            letter-spacing: -1.75rem;
            line-height: 0.45;
            color: #FFF;
            text-align: center;
        }

        p {
            font-size: 2rem;
            letter-spacing: -0.375rem;
            line-height: 0.45;
            color: #FFF;
            text-align: center;
        }

        .spaceship-1 {
            width: 5rem;
        }

        .spaceship-2 {
            width: 4rem;
            margin-bottom: -5rem;
            margin-left: 3rem;
        }

        .spaceship-3 {
            width: 7rem;
            margin-right: 1rem;
            margin-top: 1rem;
        }

        .spaceship-4 {
            width: 5rem;
            transform: scaleX(-1);
        }

        .total-wallets-container {
            position: relative;
            text-align: center;
            margin: 2rem auto; // Changed from 2rem 0 to 2rem auto
            max-width: fit-content; // Added to ensure container only takes necessary width
        }

        .total-wallets-content {
            display: inline-block;
            position: relative;
            padding: 10px; // Add some padding inside the border
        }

        .total-wallets-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            letter-spacing: -0.1rem;
        }

        .total-wallets-count {
            font-size: 5rem;
            font-weight: bold;
            letter-spacing: -0.1rem;
        }

        .total-wallets-frame {
            .total-wallets-bg {
                height: 190px;

                @media(width <=1180px) {
                    height: 150px;
                }

                @media(width <=520px) {
                    height: 100px;
                }

                .total-wallet-header {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .total-wallets-count {
                color: #fbc500;
                background: url('/images/portal/counter-frame-body.webp');
                background-size: contain;
                height: 100%;
                width: 100%;
                background-position: center;
                display: flex;
                align-items: center;
                font-size: 7rem;
                position: relative;

                @media (width <=520px) {
                    font-size: 4.7rem;
                }

                .text {
                    position: relative;
                    z-index: 2;
                    text-shadow: 2px 2px 0 #000,
                        -2px 2px 0 #000,
                        -2px -2px 0 #000,
                        2px -2px 0 #000;
                }

                .text-bg {
                    font-family: 'Inversionz';
                    color: #FFF;
                    position: absolute;
                }
            }
        }
    }

    .menu-container {
        margin-left: 2.25rem;
        margin-right: 2.25rem;
    }

    .box {
        display: block;
        color: #FFF;
        text-decoration: none;
        position: relative;
        z-index: 1;
        margin: 1rem .5rem;
        transition: all .3s;
        cursor: pointer;

        .box-thumb,
        .box-desc {
            position: relative;
            z-index: 1;
        }

        .box-thumb {
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 12px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 12px;
            }
        }

        .box-desc {
            background: rgb(255 255 255 / 10%);
            padding: 0.625rem;
            position: absolute;
            bottom: 12px;
            width: calc(100% - 4px);
            margin-left: 2px;
            transition: all .3s;
            backdrop-filter: blur(3px);

            .title {
                font-size: 3rem;
                letter-spacing: -0.625rem;
                line-height: 0.5;
            }

            .sub-title {
                padding-left: 2px;
                font-family: "Montserrat", sans-serif;
            }
        }

        &::before {
            content: '';
            background: url('/images/portal/bg-box.webp');
            background-size: contain;
            background-repeat: repeat;
            border-radius: 12px;
            border: 2px solid #FFF;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            overflow: hidden;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: -12px;
            bottom: -12px;
            background: #291051;
            z-index: 0;
            border: 2px solid #FFF;
            border-radius: 2px solid #FFF;
            border-radius: 12px;
            transition: all .3s;
        }

        @media (width > 1180px) {
            &:hover {
                transform: translate(-8px, -8px);

                .box-desc {
                    background: rgba(0, 0, 0, 0.6);
                }

                &::after {
                    right: -16px;
                    bottom: -16px;
                }
            }
        }

        &.big {
            .box-thumb {
                height: 15.625rem;

                img {
                    padding: 1.5rem 1rem 2.4rem 1rem;
                }
            }
        }

        &.small {
            &::before {
                filter: saturate(1.5);
            }

            .box-thumb {
                height: 12.5rem;

                // img {
                //     padding-top: 0.5rem;
                // }
            }
        }
    }

    @media (width <=1180px) {
        .portal-frame {
            padding: 3rem 1rem;
        }

        .menu-container {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media (width <=520px) {
        .box {
            .box-desc {
                padding: .6rem .4rem;
                white-space: pre-line;
                vertical-align: bottom;

                .title {
                    font-size: 2.5rem;
                }
            }

            &.small {
                .box-thumb {
                    height: auto;
                }
            }

            &::after {
                right: -8px;
                bottom: -8px;
            }
        }

        .heading {
            h1 {
                font-size: 5rem;
                letter-spacing: -1.2rem;
                line-height: 0.5;
            }

            p {
                line-height: 0.6;
            }

            .spaceship-1,
            .spaceship-4 {
                width: 4rem;
            }

            .spaceship-2 {
                margin-left: 1rem;
            }

            .spaceship-3 {
                width: 5rem;
            }

            .total-wallets-title {
                font-size: 2rem;
            }

            .total-wallets-count {
                font-size: 4rem;
            }
        }

        .portal-header-frame {

            .title {
                font-size: 4rem;
            }
            
            .text-desc-top,
            .text-desc-bottom {
                // line-height: 0.5;
                font-size: 1.2rem;
            }

            &.card-guide {
                .portal-frame {
                    .title {
                        line-height: 0.5;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.cards {
    &.game {
        .box {

            // First row (4 items)
            &:nth-child(-n+4) {
                // Styles for first row
            }

            // Second row (3 items)
            &:nth-child(n+5) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}