@import '../index.scss';

.table-carousel {

    .table-header {
        margin-top: 1rem;

        .main-title {
            >div {
                font-size: 2.3rem;
                letter-spacing: -0.3rem;
                padding: 1.5rem 2rem;
                margin-left: 1.5rem;
                text-align: center;
                line-height: 0.5;
            }
        }

        .sub-title {
            font-size: 1.3rem;
            // letter-spacing: -0.2rem;
            @extend %font-secondary;
        }

        @media (width <=$breakpoint-tablet) {
            .main-title {
                >div {
                    padding: 1rem;
                    font-size: 2rem;
                }
            }
        }

        @media (width <=$breakpoint-mobile) {
            .main-title {
                >div {
                    font-size: 1.8rem;
                    letter-spacing: -0.4rem;
                    text-align: left;
                    padding: 1rem;
                    margin-left: 0.5rem;
                }
            }

            .sub-title {
                font-size: 1.5rem;
            }
        }
    }

    .table-container {
        border: 2px solid #FFF;
        border-radius: 2rem;
        background: #9057FF8A;

        &.main {
            background: url('../images/bg-table.png');
            border: 3px solid $color-yellow;
        }

        .sub-header {
            font-size: 2rem;
            letter-spacing: -0.4rem;
            padding: 1rem 2rem;
            position: relative;
            z-index: 122;
            border-bottom: 3px solid $color-yellow;

            &::before {
                content: "";
                border-radius: 1rem 1rem 0 0;
                background: url('../images/bg-table.png');
                filter: brightness(0.5);
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
    }

    .table-item {
        padding: 1.5rem 1rem;

        .item {
            font-size: 3rem;
            font-weight: bold;
            letter-spacing: -0.6rem;
            line-height: 0.5;

            .hint {
                position: absolute;
                left: .5rem;
                bottom: -1rem;
            }

            .icon {
                width: 3rem;

                img {
                    width: 100%;
                }
            }

            &.main {
                .icon {
                    width: 4rem;
                    min-width: 4rem;
                    margin-right: 8px;
                }
            }

            .item-title {
                font-size: 2.5rem;
                letter-spacing: -0.6rem;
            }

            .item-desc {
                // font-size: 1.5rem;
                // letter-spacing: -0.3rem;
                // line-height: 0.8;
                // font-weight: normal;
                padding-top: .4rem;
                @extend %font-secondary;
            }

            .btn {
                .icon {
                    width: 24px;
                }
            }
        }

        @media (width <=$breakpoint-mobile) {

            .item {
                font-size: 2rem;

                .icon {
                    width: 1.5rem;
                }

                &.main {
                    .icon {
                        display: none;
                    }
                }

                .item-title {
                    font-size: 2rem;
                    letter-spacing: -0.2rem;
                }
            }
        }
    }

    .slider-container {
        // max-width: 85%;
        margin: auto;
        padding: 2rem 1rem;

        .title {
            font-size: 3rem;
            letter-spacing: -0.5rem;
            font-weight: bold;
            text-align: center;

            .icon {
                width: 24px;
            }
        }

        .desc {
            font-size: 2rem;
            letter-spacing: -0.5rem;
        }

        .milestone {
            img {
                width: 5rem;
            }

            .item-title {
                line-height: 1;
                font-size: 1.5rem;
            }

            .item-desc {
                font-size: 2rem;
            }

            .item-value {
                font-size: 5rem;
                font-weight: bold;
                letter-spacing: -1rem;
            }
        }
    }

    .info {
        .info-text {
            font-size: .8em;
        }
    }

    @media (width > $breakpoint-mobile) {
        .slider-container {
            display: none;
        }
    }

    @media (width <=$breakpoint-mobile) {
        .table-container {
            margin-bottom: 4rem;

            .sub-header {
                text-align: center;
            }
        }

        .table-header {
            .sub-title {
                display: none;
            }

            .main-title {
                margin: auto;
                width: 70%;

                >div {
                    width: 100%;
                    text-align: center;
                    font-size: 3rem;
                    letter-spacing: -0.5rem;
                }
            }
        }

        .table-item {
            display: none;
        }

        .slider-item {
            .btn {
                font-size: 3rem;
                letter-spacing: -0.7rem;
                padding: 1rem 2rem;

                .icon {
                    width: 32px;
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        &:before {
            content: "";
            width: 20px;
            height: 20px;
            display: block;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            background: url('../images/arrow-left.svg') no-repeat center;
        }
    }

    .slick-next {
        right: 0;

        &:before {
            background: url('../images/arrow-right.svg') no-repeat center;
        }
    }
}