@import "../index.scss";

body {
    background: url('../images/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $font-inversionz;
    color: #FFF;
}

main {
    min-height: calc(100vh - 253px);
    padding-bottom: 3rem;

    @media (width <= $breakpoint-tablet) {
        min-height: calc(100vh - 197px);
    }

    &.login {
        +.footer {
            opacity: 0;
        }
    }

    &.loading {
        pointer-events: none;
        
        &::before {
            content: "";
            background-color: #00000067;
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1000;
            backdrop-filter: blur(3px);
            border-radius: 1rem;
        }

        &::after {
            content: "";
            width: 48px;
            height: 48px;
            border: 5px solid #FFF;
            border-bottom-color: transparent;
            border-radius: 50%;
            animation: rotation 1s linear infinite;
            position: fixed;
            left: calc(50% - 24px);
            z-index: 1000;
            top: calc(50% - 24px);
        }
    }
}

// General
.btn {
    padding: 1rem;
    position: relative;
    font-size: 1.6rem;
    letter-spacing: -0.2rem;
    cursor: pointer;
    transition: all .3s;
    z-index: 1;
    background-color: transparent;
    color: #FFF;
    // font-family: $font-inversionz;
    line-height: 0.5;
    border: none;
    font-weight: bold;
    display: flex;
    align-items: center;

    .icon {
        width: 24px;
        margin-right: .5rem;

        img {
            width: 100%;
        }
    }

    &::before {
        content: "";
        border-radius: .8rem;
        border: 2px solid #FFF;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color-primary;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &::after {
        transition: all .3s;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #291051;
        position: absolute;
        border-radius: .8rem;
        border: 2px solid #FFF;
        left: .4rem;
        top: .4rem;
        z-index: -3;
    }

    &.btn-secondary {
        background-color: #884FEB;
        border: 2px solid #FFF;
        border-radius: .5rem;
        padding: 1rem 1rem;

        &::before,
        &::after {
            content: none;
        }
    }

    &.btn-yellow {
        background-color: $color-yellow;
        color: $color-primary;
        border: 2px solid #FFF;
        border-radius: .5rem;
        
        &::before,
        &::after {
            content: none;
        }
    }

    @media (width > $breakpoint-tablet) {
        &:hover {
            transform: translate(-2px, -2px);

            &::after {
                left: .5rem;
                top: .5rem;
            }
        }
    }
}

.container {
    padding-left: 1rem;
    padding-right: 1rem;
}

.header {
    padding: 2rem 0;

    .logo {
        width: 5rem;

        img {
            width: 100%;
        }
    }

    .page-title {
        background-color: #481C8E;
        letter-spacing: -0.2rem;
        border: 2px solid #FFF;
        padding: .2rem 2rem;
        border-radius: .5rem;
        line-height: 1;
        
        &.active {
            background-color: $color-yellow;
            color: $color-primary;
        }
    }

    .btn-connect {
        margin-right: 1.5rem;
        font-size: 1.5rem;
        background-color: #7A6A94;
        letter-spacing: -0.2rem;
        border: 2px solid #FFF;
        padding: .2rem 2rem;
        border-radius: .5rem;
        line-height: 1;
    }

    .btn-menu {
        width: 32px;

        img {
            width: 100%;
        }
    }

    .menu {
        letter-spacing: -0.2rem;

        @media(width > $breakpoint-tablet) {
            &:hover {
                text-shadow: 2px 2px 0 #FD00CA,
                -1px 2px 0 #FD00CA,
                -2px 2px 0 #FD00CA,
                -2px 1px 0 #FD00CA,
                -2px -2px 0 #FD00CA,
                -1px -1px 0 #FD00CA,
                1px 1px 0 #FD00CA,
                2px -2px 0 #FD00CA;
            }
        }
    }

    // .dropdown {
    //     background-color: $bg-purple-dark-transparent;
    //     font-size: 1.8rem;
    //     width: auto;
    //     backdrop-filter: blur(3px);
    //     border: 2px solid $bg-purple-light-transparent;
    //     border-radius: 1rem;

    //     a {
    //         white-space: nowrap;

    //         &:hover {
    //             background-color: $bg-layer-transparent;
    //         }
    //     }
    // }

    .mobile-wallet {
        padding: .6rem 1rem;
        line-height: 0.7;
    }

    @media (width <=$breakpoint-mobile) {
        .page-title {
            padding: .2rem 1rem
        }
    }
}

.modal {
    background-color: #0000002e;
    backdrop-filter: blur(3px);
}

.footer {
    border-top: 2px solid #ffffff5f;
    background: #3B266D80;
    padding-top: 1rem;
    padding-bottom: 2rem;

    .logo {
        width: 150px;

        img {
            width: 100%;
        }
    }

    .copyright {
        .symbol {
            font-family: monospace;
        }
    }

    .social-media {
        .icon {
            height: 18px;
            margin-left: .5rem;

            &.telegram {
                height: 1.3rem;
            }

            img {
                height: 100%;
                width: auto;
            }
        }
    }
}

.quest-table {
    border: 2px solid #FFF;
    border-radius: 1rem;
    background: #9057FF8A;

    &.main {
        background: url('../images/bg-table.png');
        border: 3px solid $color-yellow;
    }

    .sub-header {
        font-size: 2rem;
        letter-spacing: -0.4rem;
        padding: 1rem 2rem;
        position: relative;
        z-index: 122;
        border-bottom: 3px solid $color-yellow;

        &::before {
            content: "";
            border-radius: 1rem 1rem 0 0;
            background: url('../images/bg-table.png');
            filter: brightness(0.5);
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}

.quests-column {
    margin-top: 1rem;

    .main-title {
        font-size: 2.3rem;
        letter-spacing: -0.3rem;
        padding: 1.5rem 2rem;
        margin-left: 1.5rem;
        text-align: center;
        line-height: 0.5;
    }

    .sub-title {
        font-size: 1.5rem;
        letter-spacing: -0.2rem;
    }

    @media (width <=$breakpoint-tablet) {
        .main-title {
            padding: 1rem;
            font-size: 2rem;
        }
    }

    @media (width <=$breakpoint-mobile) {
        .main-title {
            font-size: 1.8rem;
            letter-spacing: -0.4rem;
            text-align: left;
            padding: 1rem;
            margin-left: 0.5rem;

        }

        .sub-title {
            font-size: 1.5rem;
        }
    }
}

.quests-item {
    padding: 1.5rem 1rem;

    .item {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: -0.6rem;
        line-height: 0.5;

        .hint {
            position: absolute;
            left: .5rem;
            bottom: -1rem;
        }

        .icon {
            width: 3rem;

            img {
                width: 100%;
            }
        }

        &.main {
            .icon {
                width: 4rem;
                margin-right: 8px;
            }
        }

        .item-title {
            font-size: 2.5rem;
            letter-spacing: -0.6rem;
        }

        .item-desc {
            font-size: 1.5rem;
            letter-spacing: -0.3rem;
            line-height: 0.8;
            font-weight: normal;
        }
    }

    @media (width <=$breakpoint-mobile) {

        .item {
            font-size: 2rem;

            .icon {
                width: 1.5rem;
            }

            &.main {
                .icon {
                    display: none;
                }
            }

            .item-title {
                font-size: 2rem;
                letter-spacing: -0.2rem;
            }
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
