@import "../index.scss";

.modal-login {

    .modal-bg {
        padding: 2rem;
        border-radius: 1rem;
        border: 2px solid #FFF;
        background-color: $color-yellow;
        color: $color-primary;
        z-index: 22;
        position: relative;
        transition: all .3s;
    }

    .modal-blurred {
        filter: blur(4px) brightness(0.7);
        pointer-events: none;
    }

    h1 {
        font-size: 4rem;
        letter-spacing: -0.5rem;
        font-weight: bold;
        line-height: 0.6;
    }

    p {
        font-size: 2.5rem;
        letter-spacing: -0.6rem;
    }

    .separator {
        background: $color-primary;
        font-size: 3rem;
        padding: .5rem 0;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            background: $color-primary;
            position: absolute;
            width: 30px;
            height: 120%;
            left: -20px;
            top: -8px;
            border-left: 2px solid #FFF;
            rotate: -18deg;
            z-index: -1;
        }

        &::after {
            content: '';
            background: $color-primary;
            position: absolute;
            width: 30px;
            height: 120%;
            right: -20px;
            top: -8px;
            border-right: 2px solid #FFF;
            rotate: 18deg;
            z-index: -1;
        }
    }

    .input-code {
        font-size: 2rem;
        letter-spacing: -0.4rem;
        width: 100%;
        border: 2px solid $color-primary;
        border-radius: .5rem;
        padding: .5rem 1rem;

        &::placeholder {
            color: $bg-purple-light;
        }
    }

    .code-error {
        font-size: 4rem;
        position: fixed;
        letter-spacing: -0.8rem;
        visibility: hidden;
        opacity: 0;
        transition: all .3s;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
}