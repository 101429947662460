@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$color-primary: #481C8E;
$color-yellow: #FFCC5A;
$bg-layer-transparent: #9057FF54;
$bg-purple-light: #9057FF;
$bg-purple-light-transparent: #9057FFB2;
$bg-purple-dark-transparent: #3D1CA5A6;
$font-inversionz: 'InversionzUnboxed';
$breakpoint-tablet: 1180px;
$breakpoint-mobile: 580px;
$font-secondary: "Montserrat", sans-serif;

@font-face {
  src: url("/fonts/InversionzUnboxed.ttf") format("opentype");
  font-family: "InversionzUnboxed";
}

@font-face {
  src: url("/fonts/Inversionz.ttf") format("opentype");
  font-family: "Inversionz";
}

:root {
  font-size: 16px;

  @media (width <= $breakpoint-tablet) {
      font-size: 12px;
  };

  // @media (width <= $breakpoint-mobile) {
  //     font-size: 10px;
  // };
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  // font-style: normal;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: normal;
}

%font-secondary {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  font-size: 1rem;
  line-height: normal;
}